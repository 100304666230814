import { createSlice } from '@reduxjs/toolkit';
import { thunks } from './thunks';
import { selectors } from './selectors';

const initialState = {
  isFirstLogin: false,
  profile: null,
  selectedStudentId: null,
};

const slice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setSelectedStudentId(state, action) {
      state.selectedStudentId = action.payload;
    },
    resetProfileState() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getProfile.fulfilled, (state, action) => {
        const { is_first_login } = action.payload?.data;

        state.isFirstLogin = is_first_login;
        state.profile = action.payload?.data;
      })
      .addCase(thunks.changeProfileFirstLogin.fulfilled, (state, action) => {
        const { is_first_login } = action.payload?.data;

        state.isFirstLogin = is_first_login;
      })
      .addCase(thunks.getFirstStudent.fulfilled, (state, action) => {
        const { id } = action.payload?.data;

        state.selectedStudentId = id;
      });
  },
});

const profile = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { profile };
export default slice.reducer;
